<template>
  <section class="ai-grading-section">
    <div class="ai-header">
      <h1>Efficient AI Grading Services</h1>
      <p style="color: white">Your Partner in Automated Grading Solutions</p>
    </div>

    <div class="ai-features">
      <div class="feature-card">
        <h3>Speed & Efficiency</h3>
        <p>Instantly grade hundreds of assignments with AI precision.</p>
      </div>
      <div class="feature-card">
        <h3>Consistency & Fairness</h3>
        <p>Ensure consistent, unbiased grading across all tasks.</p>
      </div>
      <div class="feature-card">
        <h3>Real-Time Feedback</h3>
        <p>Offer immediate insights for improvement.</p>
      </div>
    </div>

    <div class="ai-cta">
<!--      <button class="cta-button">Get Started</button>-->
      <v-progress-linear></v-progress-linear>
    </div>
  </section>
</template>

<script>
export default {
  name: "AigradingServices"
}
</script>

<style scoped>
.ai-grading-section {
  background: #000000;
  color: #fff;
  padding: 60px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.ai-header h1 {
  font-size: 3.5em;
  margin-bottom: 15px;
  color: white;
}

.ai-header p {
  font-size: 1.8em;
  color: #a1d8eb;
}

.ai-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 40px;
  margin-top: 30px;
}

.feature-card {
  background: #1f1f1f;
  padding: 20px;

  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.feature-card h3 {
  margin-top: 15px;
  font-size: 1.8em;
  color: white;
}

.feature-card p {
  font-size: 1.4em;
  color: #8f8f8f;
}

.ai-cta {
  margin-top: 60px;
}



/* Responsive Styling */
@media (max-width: 768px) {
  .ai-header h1 {
    font-size: 2.5em;
  }

  .ai-header p {
    font-size: 1.2em;
  }

  .cta-button {
    padding: 15px 30px;
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .ai-header h1 {
    font-size: 2em;
  }

  .ai-header p {
    font-size: 1em;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 1.1em;
  }

  .ai-features {
    grid-template-columns: 1fr;
  }
}

/* 4K Specific Adjustments */
@media (min-width: 2560px) {
  .ai-header h1 {
    font-size: 5em;
  }

  .ai-header p {
    font-size: 2.5em;
  }

  .feature-card h3 {
    font-size: 2.5em;
  }

  .feature-card p {
    font-size: 1.8em;
  }

  .cta-button {
    padding: 25px 50px;
    font-size: 2em;
  }
}
</style>
