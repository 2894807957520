<template>
  <div class="voice-note-wrapper">
    <audio
        ref="audioRef"
        :src="voiceNoteSrc"
        controls
        class="audio"
        @play="startOrResumeTranscription"
        @pause="pauseTranscription"
        @timeupdate="syncWithAudio"
        @ended="onAudioEnded"
    ></audio>
    <div class="voice-note">
      <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div class="initial-text" v-if="!audioStarted && !hasInitialTextBeenDisplayed">
          Your transcription text will appear here!
        </div>
      </transition>
      <div class="transcription" v-if="audioStarted || hasInitialTextBeenDisplayed">
        <p v-for="(sentence, index) in sentences" :key="index" class="line">
          <span v-for="(char, charIndex) in lineText(index)" :key="charIndex" class="char ai-text">
            {{ char === ' ' ? '\u00A0' : char }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, watch } from 'vue';

const voiceNoteSrc = "/voiceNoteAi.mp3";
const sentences = [
  "artificial intelligence",
  " or AI",
  "is a transformative technology",
  "that mimics human intelligence to perform tasks",
  "like learning, reasoning, and problem solving."
];
const sentenceDurations = [1900, 800, 2100, 2400, 2300]; // typing speed

const isTranscribing = ref(false);
const typedText = ref(sentences.map(() => "")); // initialize
const audioRef = ref(null);
const audioStarted = ref(false); // state to track if audio has started
const hasInitialTextBeenDisplayed = ref(false); // flag to track initial text display
let typingTimeouts = [];
let typingPositions = sentences.map(() => 0);
const totalDuration = sentenceDurations.reduce((acc, val) => acc + val, 0);
const isCurrentlyFinished = ref(false);

const startOrResumeTranscription = () => {
  if (!isTranscribing.value) {
    if (hasInitialTextBeenDisplayed.value && !isCurrentlyFinished.value) {
      resetTyping(); // reset typing if audio was played again
    } else {
      hasInitialTextBeenDisplayed.value = true; // Set the flag after initial display
    }

    isTranscribing.value = true;
    isCurrentlyFinished.value = false;
    audioStarted.value = true; // Set the state to true when audio starts
    continueTyping();
  }
};

const continueTyping = () => {
  let sentenceIndex = typingPositions.findIndex((pos, index) => pos < (sentences[index]?.length || 0));
  if (sentenceIndex === -1) sentenceIndex = 0;
  typeSentence(sentenceIndex);
};

const typeSentence = (sentenceIndex) => {
  if (sentenceIndex >= sentences.length) {
    isTranscribing.value = false;
    return;
  }

  const sentence = sentences[sentenceIndex];
  if (!sentence) return;
  const typingSpeed = sentenceDurations[sentenceIndex] / sentence.length;

  const typeNextCharacter = () => {
    if (typingPositions[sentenceIndex] < sentence.length) {
      typedText.value[sentenceIndex] += sentence[typingPositions[sentenceIndex]];
      typingPositions[sentenceIndex]++;
      typingTimeouts[sentenceIndex] = setTimeout(typeNextCharacter, typingSpeed);
    } else {
      typeSentence(sentenceIndex + 1);
    }
  };

  typeNextCharacter();
};

const pauseTranscription = () => {
  if (!isTranscribing.value) return;
  isTranscribing.value = false;
  typingTimeouts.forEach(timeout => clearTimeout(timeout));
  typingTimeouts = [];
};

const resetTyping = () => {
  typedText.value = sentences.map(() => "");
  typingPositions = sentences.map(() => 0);
};

const onAudioEnded = () => {
  isTranscribing.value = false;
  typingTimeouts.forEach(timeout => clearTimeout(timeout));
  typingTimeouts = [];
  isCurrentlyFinished.value = true;
  audioStarted.value = false; // Reset the state when audio ends
};

const syncWithAudio = () => {
  if (!audioRef.value) return;
  const currentTime = audioRef.value.currentTime;
  const audioDuration = audioRef.value.duration;

  if (!audioDuration || !currentTime) return;

  const percentagePlayed = currentTime / audioDuration;
  const totalTypedLength = Math.floor(percentagePlayed * totalDuration);
  let accumulatedDuration = 0;

  sentences.forEach((sentence, index) => {
    const sentenceDuration = sentenceDurations[index];
    if (sentence && accumulatedDuration + sentenceDuration <= totalTypedLength) {
      typedText.value[index] = sentence;
      typingPositions[index] = sentence.length;
    } else if (sentence && accumulatedDuration <= totalTypedLength) {
      const typedLength = Math.floor((totalTypedLength - accumulatedDuration) / sentenceDuration * sentence.length);
      typedText.value[index] = sentence.slice(0, typedLength);
      typingPositions[index] = typedLength;
    } else {
      typedText.value[index] = "";
      typingPositions[index] = 0;
    }
    accumulatedDuration += sentenceDuration;
  });
};

const lineText = (index) => {
  return typedText.value[index]?.split("") || [];
};

// Handle seeked event to sync text
watch(audioRef, (newRef) => {
  if (newRef) {
    newRef.addEventListener("seeked", () => {
      resetTyping();
      syncWithAudio();
    });
  }
});
</script>

<style scoped>
.voice-note-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 8rem;
}

audio {
  width: 30%;
  margin: 20px 0;
  filter: drop-shadow(0 0 8px rgb(53, 48, 48));
  background-color: #000; /* Dark background for the audio element */
  color: #fff; /* Light text color for the controls */
  border-radius: 10px ; /* Rounded corners */
  padding: 10px;
  box-shadow: 0 0 10px rgba(79, 70, 70, 0.7); /* Dark shadow */
}

.voice-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 500px;
  min-height: 250px;
  max-width: 600px;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 20px grey;
  border-radius: 15px;
  color: grey;
  position: relative;
  margin: 0 auto;
}
.initial-text {
  font-size: 24px;
  color: #c3cdd0;
  text-align: center;
  line-height: 1.6;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.transcription {
  max-width: 600px;
  font-family: 'Courier New', monospace;
  font-size: 18px;
  line-height: 1.6;
  color: grey;
  margin-top: 50px; /* Adjust to create spacing between the audio and the card */
  white-space: pre-wrap; /* Ensure text wraps correctly */
  word-break: keep-all; /* Prevent breaking words in the middle */
}


.line {
  margin-bottom: 10px;
  text-shadow: 0 0 5px rgb(0, 0, 0), 0 0 10px rgb(53, 48, 48);
}

.char {
  display: inline-block;
  position: relative;
  animation: ai-blink 1.2s ease-in-out infinite alternate;
  transition: transform 0.3s ease;
}

@keyframes ai-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.ai-text {
  animation: typingEffect 1s infinite alternate ease-in-out;
}

@keyframes typingEffect {
  0% {
    transform: scale(1);
    text-shadow: 0 0 8px rgba(0, 255, 255, 0.5), 0 0 16px rgba(0, 255, 255, 0.3);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Media query for larger screens like 4K displays */
@media screen and (min-width: 2560px) {
  .voice-note {
    max-width: 1000px;
    padding: 60px;
    font-size: 24px;
  }

  .initial-text {
    font-size: 48px;
  }

  .transcription {
    font-size: 36px;
  }

  audio{
    width: 25% !important;
  }



  .char {
    font-size: 36px;
  }

  .line {
    margin-bottom: 20px;
    text-shadow: 0 0 8px rgba(0, 255, 255, 0.7), 0 0 16px rgba(0, 255, 255, 0.5);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .audio{
    width: 65%;
  }
  .voice-note-wrapper{
    margin-top: 0;
  }
  .voice-note{
    min-width: 0;
    max-width: 900px;
  }

}

@media only screen and (max-width: 768px) {
  .audio{
    width: 65%;
  }
  .voice-note-wrapper{
    margin-top: 0;
  }
  .voice-note{
    min-width: 0;
    max-width: 320px;
  }

}

</style>