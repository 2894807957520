<template>
  <section id="transcription" class="transcriptionPanel">
    <div class="container">
      <div class="row">
        <div class="col-md-6 d-flex justify-content-center align-items-center" id="voice">
          <voice-note></voice-note>
        </div>
        <div style="padding-left: 5rem;" class="col-md-6 service d-flex flex-column justify-content-center align-items-center">
          <h1 class="p-3">{{ service }}</h1>
          <div class="row">
            <div class="col-md-12">
              <p style="font-size:20px; color: white">{{ definition }}</p>
              <br>
              <p style="font-size:16px; color: white">{{ more }}</p>

            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import VoiceNote from "@/modules/pages/landingPage/components/voiceNote.vue";

export default {
  components: { VoiceNote },
  data() {
    return {
      service: 'Transcription',
      definition: 'Accurately converting spoken language into a well-structured text document or transcript, ensuring clarity and precision.' ,
          more:'Transform your spoken words into clear, organized text with our expert transcription services. Perfect for creating accurate records, detailed meeting notes, and comprehensive documentation.',
    };
  },
};
</script>

<style scoped>
.container {
  display: flex; /* or use grid if preferred */
  flex-wrap: nowrap;
  justify-content: space-between;
}

.transcriptionPanel {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service {
  background-color: transparent;

}

.service h1 {
  position: relative;
  font-size: 50px;
  background: linear-gradient(to bottom, white, grey);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

#voice {
  margin-top: 0;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    flex-direction: row; /* Ensure row direction for tablet */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    padding: 0 2rem; /* Add padding to give some space on tablet */
  }

  #voice {
    width: 50%; /* Ensure the voice section takes up half the screen */
    margin-top: 100px;
  }

  .service {
    width: 50%; /* Ensure the service section takes up the other half */
    margin-top: 100px; /* Remove large top margin on tablets */
    padding-left: 1rem; /* Adjust padding */
  }

  .service h1 {
    font-size: 40px;
  }

  .service p {
    font-size: 18px !important;
    text-align: left;
    max-width: 100%; /* Ensure the text occupies full width */
    word-wrap: break-word;
    white-space: normal;
  }
}

@media only screen and (max-width: 768px){


  .voice-note{
    min-width: 320px;
  }

  .service h1 {
    font-size: 40px;
  }
  .service {
    margin-top:150px;
    width: auto;
   margin-left: -30px;
    
  }
  .service p {
    font-size: 18px ;
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
  }

  #voice {
    order: 1;
    width: 100%;
    margin-bottom: 50px;
  }

  .container {
    margin-bottom: 100px;
    flex-direction: column-reverse;
  }
}


@media (min-width: 2560px) {
  .container {
    min-width: 2000px;
    flex-direction: row; /* Ensure row direction for large screens */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    padding: 0 4rem; /* Add more padding for larger screens */
  }

  #voice {
    margin-top: 200px;
    margin-left: -450px;
    width: 50%; /* Ensure the voice section takes up half the screen */
    margin-bottom: 0;
  }

  .service {
    margin-left: 450px;
    width: 50%; /* Ensure the service section takes up the other half */
    margin-top: 200px; /* Remove large top margin on large screens */
    padding-left: 2rem; /* Adjust padding for larger screens */
  }

  .service h1 {
    font-size: 60px; /* Larger font size for 4K */
  }

  .service p {
    font-size: 30px !important; /* Adjust text size for readability on 4K */
    text-align: left;
    max-width: 100%; /* Ensure the text occupies full width */
    word-wrap: break-word;
    white-space: normal;
  }
}
</style>
