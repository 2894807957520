<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
    
    <!-- Footer -->
  <footer class="text-center text-lg-start bg-grey-darken-3 text-white ">
    <!-- Section: Social media -->
    <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom bg-grey-darken-" >
      <!-- Left -->
      <div class="me-5 ">
        <img :src="logo" alt="logo" class="logo img-fluid">

      </div>

      <!-- Left -->
  
      <!-- Right -->
  
    </section>
    <!-- Section: Social media -->
  
    <!-- Section: Links  -->
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <div class="me-5 d-none d-lg-block">
              <img :src="logo" alt="logo" class="logo img-fluid">

            </div>
            <p class="intro">
             " Human Intelligence Taskforce " <br>
              <br>
              The best quality data to fuel the best performing models
            </p>
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 bg-grey-darken-">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Features
            </h6>
            <p>
              <a href="#dataAnnotation" class="dataText" >Data Annotation</a>
            </p>
            <p>
              <a href="#transcription" >Transcription</a>
            </p>
            <p>
              <a href="#grading" >Grading</a>
            </p>
            <p>
              <a href="#dataCollection" class="dataText">Data Collection</a>
            </p>
            <p>
              <a href="#webDesign" >Web design</a>
            </p>
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 bg-grey-darken-">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Useful links
            </h6>
            <p>
              <a href="#!">About Us</a>
            </p>
            <p>
              <a href="#!">Call Us</a>
            </p>
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 bg-grey-darken-">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4 ">Contact</h6>
<!--            <p class="text-white"><i class="bi bi-house-door-fill"></i> Mexico</p>-->
            <p class="text-white">
              <i  class="bi bi-envelope-at-fill"></i>
              info@machinekiller.ai
            </p>
<!--            <p class="text-white"><i class="bi bi-telephone-fill"></i> + 55 149 569 52</p>-->

          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->
  
    <!-- Copyright -->
    <div class="text-center bg-grey-darken- text-white" >
      © 2024 Copyright:
      <a class="text-white fw-bold" href="https://mdbootstrap.com/">MachineKiller.AI</a>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
      
    </template>
    
    <script>
     import logo from '@/assets/img/logo.png';


    export default {
      data() {
        return {
          logo
        };
      },
    
    };
    </script>
    <style scoped>
  
  
    
    a i{
      color: grey;
    }
   
     p a{
      color: white;
     }
     
     p a:hover{
      color: silver;
    }
    
    div h6{
      color: silver;
    }

    div h5{
      color: silver;
    }

    .logo {
      height: 20px;
    }
    
    .intro{
    margin-left: -50px;
    margin-top: 20px;
    }


    @media only screen and (max-width: 770px) {
 
      .intro{
        margin-left: 10px;
      }
      .logo {
      width: 150px;  
     height: auto;  
     margin-left: 50px;
  }

    }

    @media only screen and (min-width: 2560px) {
 
  footer {
    padding: 50px;
  }

  .logo {
    height: 30px;
  }

  div h5, div h6 {
    font-size: 2rem; 
    white-space: nowrap;
  }

  p {
    font-size: 1.5rem; 
  }

  .dataText{
    white-space: nowrap;
  }


}
    </style>
    