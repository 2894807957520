<template>
  <section id="dataAnnotation" >
    <section >
      <div class="container">
        <div class="row">
          <div class="col-md-6 container-texts d-flex flex-column justify-content-center align-items-center">
            <div class="p-3 ml-1">
              <h1 class="serviceTitle">{{ Service }}</h1>
              <div class="row">
              <div class="col-md-12 serviceDef">
                <p style="font-size: 20px" class="serviceDef">{{ ServiceDefinition }}</p>
              </div>
            </div>
              <ul class="tabs-controls">
                <li class="tabs-controls__item">
                  <a href="#" class="tabs-controls__link tabs-controls__link--active" data-id="1">Text</a>
                </li>
                <li class="tabs-controls__item">
                  <a href="#" class="tabs-controls__link" data-id="2">Audio</a>
                </li>
                <li class="tabs-controls__item">
                  <a href="#" class="tabs-controls__link" data-id="3">Image</a>
                </li>
              </ul>
              <p style="font-size: 18px" class="reachOut">{{ ReachOut }}</p>
            </div>
          </div>
          <div class="col-md-6" id="cards">
            <section c style="margin-top: 2rem" class="p-3 ml-8 cards-section">
              <div class="card card--current card-1 rounded-4" id="1">
                <h4 class="card-title">{{ textService }}</h4>
                <p class="card-text">{{ textDefinition }}</p>
              </div>
              <div class="card card-2 rounded-4" id="2">
                <h4 class="card-title">{{ audioAnnotation }}</h4>
                <p class="card-text">{{ audioDefinition }}</p>
              </div>
              <div class="card card-3 rounded-4" id="3">
                <h4 class="card-title">{{ imageAnnotation }}</h4>
                <p class="card-text">{{ imageDefinition }}</p>
              </div>
              <div class="rounded-2">
                <img class="robot-img" :src="robot" alt="robot image">
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import robot from '@/assets/img/robot.png';
import eventBus from "@/eventBus";

export default {
  data() {
    return {
      Service: 'Data Annotation',
      ServiceDefinition: 'Enhance your AI models with high-quality text data annotations.',
      ReachOut: 'Reach Out Today to learn more about how our text data annotation services can enhance your AI projects.',
      textService: 'Text Annotation',
      textDefinition: 'We provide expert text data annotation services to enhance your machine learning models. Specializes in labeling text for tasks such as named entity recognition, sentiment analysis, and text classification. Ensure high-quality, accuracy.',
      audioAnnotation: 'Audio Annotation',
      audioDefinition: 'We provide expert audio data annotation services to enhance your machine learning models. Specializes in labeling audio for tasks such as speech recognition and sound classification.',
      imageAnnotation: 'Image Annotation',
      imageDefinition: 'We provide expert image data annotation services to enhance your machine learning models. Specializes in labeling images for tasks such as object detection and image classification.',
      robot,
    };
  },
  beforeUnmount() {
    eventBus.$off('scrollEmit', this.scroll);
  },
  mounted() {
    eventBus.$on('scrollEmit', this.scroll);

    var oldId = null;

    var tabs = document.querySelectorAll('.tabs-controls__link');
    tabs.forEach((tab) => {
      tab.addEventListener('click', (e) => {
        e.preventDefault();

        if (tab.classList.contains('tabs-controls__link--active')) {
          return false;
        }

        var currentId = parseInt(tab.getAttribute('data-id'), 10);
        document.querySelector('.tabs-controls__link--active').classList.remove('tabs-controls__link--active');
        tab.classList.add('tabs-controls__link--active');

        var cards = document.querySelectorAll('.card');
        if (currentId < oldId) { // item is hidden
          var timing = document.querySelectorAll('.card.hidden').length * 100;
          cards.forEach((card, index) => {
            if (index >= (currentId - 1)) {
              setTimeout(() => {
                cards[index].classList.remove('hidden');
              }, timing - (index * 100));
            }
          });
        } else {
          cards.forEach((card, index) => {
            if (index < (currentId - 1)) {
              setTimeout(() => {
                cards[index].classList.add('hidden');
              }, index * 100);
            }
          });
        }

        oldId = currentId;
      });
    });
  },
  methods: {
    scroll() {
      const element = document.getElementById("dataAnnotation");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped>
.serviceTitle  {
  font-size: 40px;
  background: linear-gradient(to bottom, white, grey);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  margin-bottom: 30px;
}

.robot-img {
  width: 180px;
  margin-top: -225px;
  margin-left: 420px;
}

.cards-container {
  height: 15rem;
  overflow: hidden;
  display: block;
}

.card {
  background: linear-gradient(to right, #08090a, #262424);
  border-radius: 15px;
  overflow: hidden;
  width: 30rem;
  height: 15rem;
  border: 1px solid #423E37;
  box-shadow: 9px 10px 3px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 40px 35px 37px 40px;
  transition: transform 0.8s, opacity 0.2s;
}

.card-1 {
  position: relative;
  margin-bottom: -260px;
  margin-right: 30px;
}

.card-2 {
  position: relative;
  margin-bottom: -260px;
  margin-left: 20px;
}

.card-3 {
  position: relative;
  margin-left: 30px;
}

.card:nth-of-type(1) {
  z-index: 3;
  transform: translateX(0px);
}

.card:nth-of-type(2) {
  z-index: 2;
  transform: translateX(0px);
}

.card:nth-of-type(3) {
  z-index: 1;
  transform: translateX(0px);
}

.card-title {
  padding-bottom: 5px;
  font-weight: bold;
  background: linear-gradient(to bottom, white, grey);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.card-text {
  padding-right: 50px;
  padding-left: 20px;
  font-size: 14px;
  color: white;
}

.page {
  margin-top: 100px;
  overflow: hidden;
}

.tabs-controls {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 70%;
  height: 10px;
  box-sizing: border-box;
  padding: 10px 0 50px;
  list-style-type: none;
  background-color: rgba(192, 192, 192, 0.071);
  border-radius: 60px;
}

.tabs-controls__link {
  position: relative;
  display: block;
  padding: 4px 30px;
  font-size: 19px;
  font-weight: 700;
  color: rgb(171, 164, 164);
  text-decoration: none;
}

.tabs-controls__link:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 0;
  height: 4px;
  background-color: #423E37;
  border-radius: 2px;
  margin: auto;
  content: '';
  transition: width 0.4s;
}

.tabs-controls__link--active:after {
  width: 50%;
  height: 7%;
}

.tabs-controls__link--active {
  color: white;
}

.card.hidden {
  z-index: 100;
  background-color: #fff;
  color: #fff;
  opacity: 0;
  transition: color 0.5s, background-color 0.5s, transform 1s, opacity 0.2s 0.4s;
}

.card.hidden h1 {
  color: #fff;
  transition: color 0.5s;
}

.card:nth-of-type(1) {
  z-index: 5;
  transform: translateX(0px) translateY(0px);
}

.card:nth-of-type(2) {
  z-index: 4;
  transform: translateX(10px) translateY(10px);
}

.card:nth-of-type(3) {
  z-index: 3;
  transform: translateX(20px) translateY(20px);
}

.card.hidden:nth-of-type(1) {
  transform: rotate(50deg) translateX(101%) translateY(-40%) scale(0.6) skewX(11deg) skewY(8deg);
}

.card.hidden:nth-of-type(2) {
  transform: rotate(-30deg) translateX(102%) translateY(-30%) scale(0.8) skewX(6deg) skewY(12deg);
}

.card.hidden:nth-of-type(3) {
  transform: rotate(20deg) translateX(103%) translateY(-20%) scale(0.5) skewX(7deg) skewY(10deg);
}

@media only screen and (max-width: 1000px) {
  .card {
    width: 28rem;
    height: 15rem;
    padding: 30px 25px 30px 30px;
  }

  .card-text {
    font-size: 15px;
    padding-right: 30px;
    padding-left: 10px;
  }

  .card-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1350px) and (min-width: 1100px) {
  #cards {
    position: relative;
    margin-left: -100px;
  }
}

@media only screen and (max-width: 1100px) {
  #cards {
    position: relative;
    margin-left: -100px;
  }

  .robot-img {
    display: none;
  }
}

@media only screen and (max-width: 992px) and (min-width: 965px) {
  .tabs-controls {
    width: 100%;

  }

  .container-texts {
    position: relative;
    margin-right: 30px;
  }

  .container {
    position: relative;
    margin-left: 80px;
  }
}

@media only screen and (max-width: 965px) and (min-width: 768px) {
  .tabs-controls {
    width: 100%;
  }

  #cards {
    position: relative;
    margin-right: 200px;
  }
  .robot-img{
    visibility: visible;
  }
}

@media only screen and (max-width: 776px) and (min-width: 530px) {
  .card {
   position: relative;
   margin-left: 80px;
  }

}

@media only screen and (max-width: 530px) and (min-width: 510px) {
  .card {
   position: relative;
   margin-left: 60px;
  }

}
@media only screen and (max-width: 510px) and (min-width: 390px) {
  .cards-section{
    margin-left: 90px !important;
    margin-top: -10px !important;
  }

  .card-1{
    position: relative;
    margin-left: 20px;
    margin-bottom: -290px;
    width: 340px !important;
    height: 300px !important;

  }
  .card-2{
    position: relative;
    margin-left: 10px;
    margin-bottom: -290px;
    width: 340px !important;
    height: 300px;
  }
  .card-3{
    position: relative;
    margin-left: -1px;
    width: 340px !important;
    height: 300px;
  }

  .tabs-controls {
    width: 100%;
  }
}

@media only screen and (max-width: 390px) and (min-width: 320px) {

  .cards-section{
    margin-left: 25% !important;
  }

  .card-1{
    position: relative;
    margin-left: 5px;

    width: 280px !important;
    height: 305px;
    
  }
  .card-2{
    position: relative;
    margin-left: 5px;
    width: 280px !important;
    height: 280px;
  }
  .card-3{
    position: relative;
    margin-left: 5px;
    width: 280px !important;
    height: 275px;
  }

  .tabs-controls {
    width: 100%;
  }
}



@media only screen and (min-width: 2560px) {
  .container {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
  }

  .serviceTitle {
    font-size: 60px;
    margin-bottom: 20px;
  }

  .tabs-controls {
    margin-bottom: 50px;
    width: 50%;
    padding-bottom: 60px;
  }

  .tabs-controls__link {
    font-size: 24px;
  }

  .card {
    width: 50rem;
    height: 25rem;
    padding: 50px;
  }

  .card-title {
    font-size: 40px;
  }

  .card-text {
    font-size: 26px;
    padding-right: 70px;
    padding-left: 30px;

  }

  .robot-img {
    width: 250px;
    height: 400px;
    margin-left: 45rem;
    margin-top: -24rem;
  }

  .page {
    margin-top: 150px;
  }

  .reachOut {
    font-size: 25px !important;
    width: 1000px;
    padding-right: 100px;
  }

  .serviceDef {
    font-size: 30px !important;
  }

  .card-1 {
    position: relative;
    margin-bottom: -400px;
    margin-right: 30px;
  }

  .card-2 {
    position: relative;
    margin-bottom: -400px;
    margin-right: 30px;
  }
}
</style>