<template>
  <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css" rel="stylesheet" />

  <section id="dataCollection" class="DataCollectionPanel">
    <div class="container">
      <div class="row">
        <div class="col-md-6 service d-flex flex-column justify-content-center align-items-center">
          <h1 class="p-3">{{ service }}</h1>
          <div class="row">
            <div class="col-md-12">
              <p style="font-size: 20px;color: white">{{ definition }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex justify-content-center align-items-center whole-circle">
          <div class="parent">
            <div class="circle-line"></div>
            <div class="circle" id="sample-text">
              <h2>Data</h2>
            </div>
            <div class="image-holder">
  <i class="bi bi-filetype-pdf" data-text="<h3>PDFs</h3>" ></i>
  <i class="bi bi-file-earmark-image" data-text="<h3>Images</h3>" ></i>
  <i class="bi bi-file-earmark-music" data-text="<h3>Audios</h3>"></i>
  <i class="bi bi-file-earmark-play" data-text="<h3>Videos</h3>" ></i>
  <i class="bi bi-file-earmark-text" data-text="<h3>Text</h3>" ></i>
</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  data() {
    return {
      service: 'Data Collection',
      definition: 'Empowering Your AI and Business Insights with High-Quality Data',
    };
  },
  mounted(){
    var images = document.getElementsByTagName('i');
    var sampleText = document.getElementById('sample-text');
    for (var i = 0; i < images.length; i++) {
      images[i].addEventListener("mouseover", updateName);
    }

    function updateName() {
      sampleText.innerHTML = this.getAttribute('data-text');
    }
  }
};
</script>

<style scoped>
.container {
  text-align: center;
  position: relative;
  margin-bottom: 100px;
}

.DataCollectionPanel {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service {
  background-color: transparent;
}

.service h1 {
  font-size: 40px;
  background: linear-gradient(to bottom, white, grey);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

body {
  padding: 100px;
}

.parent {
  position: relative;
}

.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(135deg, #000000, #211826);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 20px rgba(103, 106, 107, 0.5);
  animation: glow 1.5s infinite alternate, pulse 2s infinite;
  transition: opacity 0.3s ease;
}

.circle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(194, 139, 139, 0.1) 20%, transparent 60%);
  z-index: 2;
  pointer-events: none;
}

@keyframes glow {
  from {
    box-shadow: 0 0 20px rgba(46, 53, 54, 0.5);
  }
  to {
    box-shadow: 0 0 40px rgb(80, 93, 94);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.circle-line {
  width: 300px;
  height: 300px;
  border: 2px solid rgba(140, 142, 194, 0.32);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  animation: moveCircleLine 10s linear infinite;
}

@keyframes moveCircleLine {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.image-holder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  animation: rotate 20s linear infinite;
}

.image-holder:hover {
  animation-play-state: paused;
}

.image-holder i{
  position: absolute;
  transition: transform 0.5s ease;
  width: auto;
  height: 50px;
  color: #cbc5c5;
  font-size: 50px;
}

.image-holder i:hover {
  transform: scale(1.1);
}

.image-holder i:nth-child(1) {
  bottom: -42px;
  left: -15px;
}

.image-holder i:nth-child(2) {
  top: -90px;
  left: 70px;
}

.image-holder i:nth-child(3) {
  top: 30px;
  right: -80px;
}

.image-holder i:nth-child(4) {
  top: 30px;
  left: -80px;
}

.image-holder i:nth-child(5) {
  bottom: -50px;
  right: -15px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.glowing-waves {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 20%, transparent 50%);
  animation: glowingWaves 3s infinite linear;
  z-index: 0;
}

@keyframes glowingWaves {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@media only screen and (max-width: 400px) {
  

  .container{
    margin-bottom: 400px;
  }
  .circle-line{
    width: 250px;
    height: 250px;
  }

  .circle {
    width: 150px;
    height: 150px;
    
  }
   
  .image-holder i:nth-child(1) {
    bottom: -70px;
    left: -30px;
    width: 50%;
    height: 50%;
  }

  .image-holder i:nth-child(2) {
    top: -90px;
    left: 25%;
    width: 50%;
    height: 50%;
  }

  .image-holder i:nth-child(3) {
    top: 20px;
    right: -90px;
    width: 50%;
    height: 50%;
  }

  .image-holder i:nth-child(4) {
    top: 20px;
    left: -90px;
    width: 50%;
    height: 50%;
  }

  .image-holder i:nth-child(5) {
    bottom: -70px;
    right: -30px;
    width: 50%;
    height: 50%;
  }
  .image-holder i{
     font-size: 10px;
     width: 50%;
     height: 50%;
    }
}


@media only screen and (max-width: 770px) {
  .parent {
    position: relative;
    margin-top: 90px;
  }
  .image-holder i{
     font-size: 50px;
    }
}

@media only screen and (min-width: 2560px) {


  .container {
    max-width: 100%;
    margin-right: 400px;
  }
.whole-circle{
  margin-left: -150px;
}

  .DataCollectionPanel {
    height: 900px;
  }

  .circle {

    width: 400px;
    height: 400px;
  }

  .circle h2 {
    font-size: 64px;
  }

  .circle-line {
    width: 600px;
    height: 600px;
  }

  .image-holder i {
    height: 100px;
    font-size: 85px;
  }

  .image-holder i:nth-child(1) {
    bottom: -90px;
    left: -30px;
  }

  .image-holder i:nth-child(2) {
    top: -170px;
    left: 35%;
  }

  .image-holder i:nth-child(3) {
    top: 50px;
    right: -130px;
  }

  .image-holder i:nth-child(4) {
    top: 50px;
    left: -130px;
  }

  .image-holder i:nth-child(5) {
    bottom: -90px;
    right: -30px;
  }

  .service h1 {
    font-size: 80px;
  }

  .service p {
    font-size: 24px;
  }

  .parent {
    margin-bottom: 150px;
  }
 
}

</style>