<template>
  <div>
    <nav class="navbar custom-navbar">
      <div class="container-fluid">
        <div class="navbar-section navbar-left">
          <a class="navbar-brand" href="#">
            <img :src="logo" alt="logo" class="logo">
          </a>
        </div>

        <div class="navbar-section navbar-center d-none d-lg-flex">
          <ul class="navbar-nav d-flex flex-row ">
            <li class="nav-item me-3 pl-3">
              <a class="nav-link" aria-current="page" href="">Home</a>
            </li>
            <li class="nav-item active me-3 pl-3">
              <a class="nav-link" href="">Products</a>
            </li>
            <li class="nav-item me-3 pl-3">
              <a class="nav-link" href="">Features</a>
            </li>
            <li class="nav-item me-3 pl-3">
              <a class="nav-link" href="">Pricing</a>
            </li>
            <li class="nav-item me-3 pl-3">
              <a class="nav-link" href="">About us</a>
            </li>
            <li class="nav-item pl-3">
              <a class="nav-link" href="">Call us</a>
            </li>
          </ul>
        </div>


        <div class="navbar-section navbar-right d-none d-lg-flex">
          <ul class="navbar-nav">
            <li class="nav-item d-flex align-items-center">
              <a style="font-size: 16px" class="nav-link" href="#">Sign in</a>
              <v-btn style="font-size: 15px "  color="#353030" size="small" rounded class="ms-3 mt-1 sign_up_btn"><span class="sign_up_btn_span text-none no-uppercase">Sign up</span></v-btn>
            </li>
          </ul>
        </div>

        <button
            class="d-lg-none d-block"
            type="button"
            @click="toggleSidebar"
            aria-controls="sidebar"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"> <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="white"
    viewBox="0 0 24 24"
  >
    <path d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"/>
  </svg></span>
        </button>


      </div>
    </nav>

    <div :class="['sidebar', { 'open': isSidebarOpen }]">
      <div class="sidebar-header">
        <button @click="toggleSidebar" class="close-btn">&times;</button>
        <img :src="logo" alt="logo" class="logo-side-bar">

      </div>
      <ul class="sidebar-menu">

        <li class="nav-item">
          <a class="nav-link" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Products</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Pricing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">About us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Call us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Sign in</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Sign up</a>
        </li>
      </ul>
    </div>

    <div v-if="isSidebarOpen" class="sidebar-overlay" @click="toggleSidebar"></div>
  </div>
</template>
<script>
import logo from '@/assets/img/logo.png';

export default {
  data() {
    return {
      logo,
      isSidebarOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>
<style scoped>
.custom-navbar {

  background-color: rgba(6, 5, 5, 0.88);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-toggler {
  margin-left: auto;
  border: none;
  background: transparent;
  color: white;
}

.navbar-toggler-icon {
  margin-top: 5px;
  font-size: 1.5rem;
}

.navbar-section {
  display: flex;
  align-items: center;
}

.navbar-left {
  margin-left: 3rem;
  flex: 1;
  justify-content: flex-start;
}

.navbar-center {
  margin-right: 3rem;
  flex: 1;
  justify-content: center;
}

.navbar-right {
  flex: 1;
  justify-content: center;
  gap: 2rem;
}

.logo {
  margin-top: 5px;
  margin-left: 10px;
  height: 1.2rem;
}

.logo-side-bar{
  height: 1rem;
  margin-top: 0.8rem;
}

.nav-link {
  color: white;
  white-space: nowrap;
}

.nav-link:hover {
  color: grey;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -250px; /* Initially hidden off-screen */
  width: 250px;
  height: 100%;
  background-color:#3f3d3d;
  color: white;
  overflow-y: auto;
  transition: right 0.3s ease;
  z-index: 1000;
}

.sidebar.open {
  right: 0;
}

.sidebar-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.close-btn {
  font-size: 1.5rem;
  border: none;
  background: transparent;
  color: white;
  cursor: pointer;
  margin-right: 2rem;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu .nav-item {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-menu .nav-item .nav-link {
  color: white;
  text-decoration: none;
  display: block;
}

.sidebar-menu .nav-item .nav-link:hover {
  color: grey;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 768px) {
  .logo {
    max-width: 320px;
    max-height: 0.8rem;
  }

  .navbar-section.navbar-center {
    display: none; /* Hide the center nav on smaller screens */
  }

  .navbar-right {
    margin-right: auto;
  }

  .navbar-toggler-icon {
    margin-right: 10px;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .logo {
    margin-bottom: 10px;
    max-height: 0.8rem;
  }}

@media (min-width: 2560px) {
  .navbar-section {
    font-size: 2.5rem;
  }

  .sign_up_btn{
    height: 55px;
    width: 140px;
  }

  .sign_up_btn_span{
    font-size: 24px !important;
  }
  .logo {
    height: 30px;
  }

  .navbar-section.navbar-left {
    margin-left: 5rem;
  }

  .navbar-section.navbar-center {
    margin-right: 5rem;
  }

  .no-uppercase {
    text-transform: unset !important;
  }

  .nav-item {
    margin-right: 4rem;
  }

  .navbar-right {
    gap: 3rem;
  }

}





</style>