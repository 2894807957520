<template>
  <v-app>
    <landing-page />
  </v-app>
</template>

<script>
import LandingPage from "./views/landing-page.vue";

export default {
  name: 'App',

  components: {
    LandingPage,
  },

  data: () => ({
  }),
}
</script>


<style scoped>

</style>
