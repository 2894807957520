<template>
  <div id="particles-js">
    <div class="intro">
      <v-card class="intro-card">
        <v-card-title class="headline">
          " Human Intelligence Taskforce "
          <span class="underline"></span>
        </v-card-title>
        <v-card-text class="upper-text">
          The best quality data to fuel the best performing models
        </v-card-text>
      </v-card>
      <div class="intro-buttons row-2">
        <v-btn @click="emitScrollEvent" class="get_started_button" color="#353030" rounded>
          <span class="get_started_button_span">Services</span>
        </v-btn>
        <v-btn class="explore_plans_button" color="transparent" rounded>
          <span class="explore_plans_span">Explore Plans</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';

export default {
  mounted() {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js";
    script.onload = () => {
      window.particlesJS("particles-js", {
        particles: {
          number: { value: 80, density: { enable: true, value_area: 800 } },
          color: { value: "#ffffff" },
          shape: { type: "circle", stroke: { width: 0, color: "#000000" }, polygon: { nb_sides: 5 }, image: { src: "img/github.svg", width: 100, height: 100 } },
          opacity: { value: 0.5, random: false, anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false } },
          size: { value: 2, random: true, anim: { enable: false, speed: 40, size_min: 0.1, sync: false } },
          line_linked: { enable: true, distance: 150, color: "#ffffff", opacity: 0.2, width: 1 },
          move: { enable: true, speed: 6, direction: "none", random: false, straight: false, out_mode: "out" }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "repulse" },
            onclick: { enable: true, mode: "push" },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
    };
    document.head.appendChild(script);

    setTimeout(() => {
      const headline = document.querySelector('.headline');
      if (headline) {
        headline.classList.add('finished-typing');
      }
    }, 2500);
  },
  methods: {
    emitScrollEvent() {
      eventBus.$emit('scrollEmit');
    }
  }
};
</script>

<style scoped>
#particles-js {
  position: relative;
  width: 100%;
  height: 500px;
  z-index: 1;
}

.intro {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.intro-card {
  filter: drop-shadow(0 0 55px rgb(115, 108, 108));

  color: white;
  background-color: transparent;
}

.headline {
  text-align: center;
  font-size: 50px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  animation: typing 2.5s steps(40, end), blink 0.55s step-end infinite;
  border-right: 2px solid rgba(255, 255, 255, 0.7);
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink {
  from, to { border-color: transparent; }
  80% { border-color: rgba(255, 255, 255, 0.7); }
}

.finished-typing {
  border-right: none;
}

.underline {
  display: block;
  margin-left: 80px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 70%;
  margin-top: 4px;
}

.upper-text {
  text-align: center;
}

.intro-buttons {
  padding-top: 50px;
  text-align: center;
}

.explore_plans_button {
  margin-left: 10px;
}

.explore_plans_span:hover {}



@media (min-width: 768px) and (max-width: 1024px)  {
  .headline{
    width: 100%;
    font-size: 45px;
  }
  #particles-js{
    width: 100%;
  }
  .intro{
    width: 100%;
  }
  .underline{
    width: 50%;
    margin: 0 auto;

  }

}


@media only screen and (max-width: 768px)  {
    .headline{
     width: 100%;
     font-size: 19px;
    }
    #particles-js{
      width: 100%;
    }
    .intro{
      width: 100%;
    }
    .underline{
      width: 50%;
      margin: 0 auto; 

    }
  
  }

@media only screen and (min-width: 2560px)
{
  .headline{
    width: 100%;
    font-size: 100px;
  }
  .underline {
    display: block;
    margin-left: 80px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.7);
    width: 90%;
    margin-top: 4px;
  }
  .upper-text {
    text-align: center;
    font-size: 30px;
  }
  .get_started_button_span{
    font-size: 25px;
  }
  .get_started_button{
    height: 55px;
    width: 160px;
  }
  .explore_plans_span{
    font-size: 25px;
    text-align: center;
  }
}

</style>
