<template>
  <section id="webDesign">
    <div class="container">
      <div class="row">
        <div class="col-md-6 service d-flex flex-column justify-content-center align-items-center">
          <div class="wrapper">
            <div class="static-txt">Web Design</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="wrapper">
                <div class="dynamic-txt" :class="{ 'animate': isVisible }">
                  <span>Secure</span>
                  <span>Scalable</span>
                  <span>Optimized</span>
                  <span>Responsive</span>
                  <span>User-Friendly</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- First column for "Animation" -->
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <!-- Your image slider code here -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  data() {
    return {
      isVisible: false, // To track if section is in view
    };
  },
  mounted() {
    this.observeSection();
  },
  methods: {
    observeSection() {
      const section = document.querySelector('#webDesign');
      const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting === true) {
              this.isVisible = true; // Trigger the animation
              observer.unobserve(section); // Stop observing once the section is in view
            }
          },
          { threshold: [0.5] } // Adjust this value if needed
      );
      observer.observe(section);
    },
  },
};
</script>

<style scoped>
/* General Container Styling */
.container {
  background-image: url("@/assets/img/codingIMG2.jpg");
  background-color: #1f1f1f;
  width: 100%;
  height: 500px;
  margin-bottom: 100px;
  background-size: cover;
}

/* Wrapper for Text */
.wrapper {
  display: flex;
  text-align: center;
}

/* Static and Dynamic Text Styling */
.wrapper .static-txt {
  margin-top: 4rem;
  font-size: 60px;
  color: white;
  font-weight: 600;
  margin-right: 50px;
  margin-bottom: 20px;
  text-shadow: 2px 4px 6px rgb(76, 80, 82);
  letter-spacing: 2px;
}

.dynamic-txt {
  font-size: 30px;
  font-weight: 500;
  color: #b0b2b6;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.dynamic-txt span {
  margin: 5px 0;
  opacity: 0;
  transform: translateY(20px);
}

.dynamic-txt.animate span {
  animation: fadeInUp 1s forwards;
}

.dynamic-txt.animate span:nth-child(1) {
  animation-delay: 0.5s;
}

.dynamic-txt.animate span:nth-child(2) {
  animation-delay: 1s;
}

.dynamic-txt.animate span:nth-child(3) {
  animation-delay: 1.5s;
}

.dynamic-txt.animate span:nth-child(4) {
  animation-delay: 2s;
}

.dynamic-txt.animate span:nth-child(5) {
  animation-delay: 2.5s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Image Slider */
.slide-c {
  width: 300px;
  height: 300px;
  position: relative;
}

.slide-c img {
  border-radius: 50px;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
  display: block;
  position: absolute;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

/* Responsive Styling for Mobile and Tablets */
@media only screen and (max-width: 990px) and (min-width: 771px) {
  .wrapper .static-txt {
    font-size: 40px;
  }
  .dynamic-txt {
    font-size: 25px;
  }
}

@media only screen and (max-width: 770px) and (min-width: 320px) {
  .wrapper .static-txt {
    font-size: 32px;
    margin-left: 25px;
    margin-bottom: 2rem;
  }
  .dynamic-txt {
    font-size: 28px;
  }
  .slide-c {
    width: 200px;
    height: 200px;
  }
}

/* 4K Display Adjustments */
@media only screen and (min-width: 2560px) {
  .container {
    max-width: 1600px;
    height: 800px;
  }
  .wrapper .static-txt {
    font-size: 70px;
    margin-top: 6rem;
  }
  .dynamic-txt {
    font-size: 40px;
    margin-top: 5rem;
  }
  .slide-c {
    width: 400px;
    height: 400px;
  }
  .slide-c img {
    border-radius: 80px;
  }
}
</style>
