
  <template>
    <v-app>
<!--      <v-main style="color: white; background: linear-gradient(to bottom, #050505, #070707, #090909, #0b0b0b, #0d0d0d, #0f0f0f);">-->
            <v-main style="color: white; background-color: black">

      <NavbarPanel />
        <IntroSection />
              <DataAnnotation />
        <TranscriptionPanel />
        <DataCollection />

              <GradingPanel />
              <WebDesign />


              <world-map></world-map>
        <FooterPanel />
      </v-main>
    </v-app>
  </template>

  <script>
  import GradingPanel from '@/modules/pages/landingPage/components/GradingPanel.vue';
  import NavbarPanel from '@/components/NavbarPanel.vue';
  import DataAnnotation from '@/modules/pages/landingPage/components/DataAnnotation.vue';
  import DataCollection from '@/modules/pages/landingPage/components/DataCollection.vue';
  import FooterPanel from '@/components/FooterPanel.vue';
  import TranscriptionPanel from '@/modules/pages/landingPage/components/TranscriptionPanel.vue';
  import IntroSection from "@/modules/pages/landingPage/components/IntroSection.vue";
import WebDesign from '@/modules/pages/landingPage/components/WebDesign.vue';
  import WorldMap from "@/modules/pages/landingPage/components/worldMap.vue";


  export default {
    components: {
      WorldMap,
      IntroSection,
      DataAnnotation,
      TranscriptionPanel,
      GradingPanel,
      DataCollection,
      WebDesign,
      NavbarPanel,
      FooterPanel,
    },

    data: () => ({
      //
    }),
  }
  </script>

<style scoped>



</style>